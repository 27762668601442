.MenuPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0
}

.dishes {
  overflow-y: scroll;
}
