.dishImg {
  width: 18.75rem;
  height: 28.125rem;
}
.dishDetail {
  flex: 1;
  text-align: left;
}
.dishPrice {
  flex: 1;
  text-align: right;
}
.addIcon {
  flex: 0;
  text-align: right;
}
