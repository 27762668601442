.container {
  position: relative;
  text-align: center;
}

.backgroundContainer {
  background-image: url("../resources/images/background_welcome.jpg");
  background-size: cover;
  width: 100vw;
  position: absolute;
  background-repeat: no-repeat;
  background-attachment: fixed;
  filter: blur(0.1875rem);
  z-index: 1;
}

.homeContainer {
  padding: 5%;
  position: relative;
  width: 100vw;
  font-size: 1.375rem;
  vertical-align: middle;
  z-index: 2;
}

.buttonPos {
  margin-top: 3.125rem !important;
}

.termContainer {
  position: absolute;
  width: 100vw;
  padding: 5%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: white;
  z-index: 2;
}

.typoStyle, .tableTypoStyle {
  -webkit-text-stroke: .025rem white;
}

.tableTypoStyle {
  font-size: 2rem;
}

.termStyle {
  cursor: pointer;
  color:#0000EE;
  -webkit-tap-highlight-color: transparent;
}