.menuRoot {
  width: 100%;
  height: 3.125rem;
  background-color: #efefef;
  display: flex;
  justify-content: space-between;
  padding: 0.9375rem 1.25rem;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 0.625rem;
}
.info {
  font-size: 1.125rem;
  font-weight: 700;
}
