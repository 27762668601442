.brownButton {
    color: #ffffff !important;
    background-color: #904b40 !important;
    width: 5px !important;
    height: 5px !important;
}
.manipulateBtnLeft{
    background: #ffffff !important ;
    color: #949494 !important ;
    border: 0.5px solid #A9A9A9 !important;
    border-radius: 2px 0px 0px 2px  !important;
    width: 16px!important;
    height:25px!important;
}
.manipulateBtnRight{
    background: #ffffff !important ;
    color: #949494 !important ;
    border: 0.5px solid #A9A9A9 !important;
    border-radius: 0px 2px 2px 0px  !important;
    width: 16px!important;
    height:25px!important;
}

.qty{
    font-family: 'Inter';
    border: 0.5px solid #A9A9A9 !important;
    width: 16px!important;
    height:25px!important;
    font-style: normal!important;
    font-weight: 700!important;
    font-size: 12px!important;
    line-height: 4px!important;
    display: flex!important;
    align-items: center!important;
    text-align: center!important;
    color: #000000!important;
}
.btnGroup{
    margin-left: 20px;
}