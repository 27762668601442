.cart-page {
  align-items: center;
  background-color: #ffffff;
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 26px;
  height: 844px;
  overflow: hidden;
  padding: 151px 0;
  width: 390px;
}

