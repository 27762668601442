.categoryRoot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2;
    height: 2.8125rem;
    width: 100%;
    margin-bottom: 0.75rem;
}

.button {
    padding: 1;
}